"use client";

import { API_URL } from "@/app/config";
import { getCookie } from "cookies-next";
import { useCallback, useEffect, useState } from "react";
import { ReqError } from "../helpers/ReqError";
import useAuthStore from "@/store";

const useLoadMe = ({
  rerouteToLoginOnFail = false,
  showToastError = false,
}) => {
  const { me, setMe, setAuthToken } = useAuthStore();
  const authToken = getCookie("auth");

  const queryMe = useCallback(async () => {
    if (!authToken) {
      if (rerouteToLoginOnFail) window.location.replace("/login");
      return;
    }

    setAuthToken(authToken);

    const headers = { Authorization: authToken };
    const response = await fetch(`${API_URL}/me`, { headers });
    const value = await response.json();

    setMe(value);

    if (!response.ok) {
      new ReqError({
        response,
        title: "Failed to get user data",
        data: value,
        rerouteToLogin: rerouteToLoginOnFail,
        showToastError,
      });
    }
  }, [authToken]);

  useEffect(() => {
    queryMe();

    const onStorageChange = (event) => {
      if (event.storageArea === localStorage) {
        if (event.oldValue !== null && event.newValue === null) {
          queryMe();
        }
      }
    };

    const onLocalStorageChange = () => {
      queryMe();
    };

    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        queryMe();
      }
    };

    window.addEventListener("storage", onStorageChange);
    window.addEventListener("localStorageChange", onLocalStorageChange);
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
      window.removeEventListener("localStorageChange", onLocalStorageChange);
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [queryMe]);

  return me;
};

export default useLoadMe;
