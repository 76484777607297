"use client";

import RightArrow from "../../(svgIcons)/rightArrow.svg";
import Logout from "../Logout/Logout";
import HamburgerMenu from "./HamburgerMenu/HamburgerMenu";
import GetYourHeadshotsButton from "../GetYourHeadshotsButton/GetYourHeadshotsButton";
import Link from "next/link";
import styles from "./Header.module.css";
import useShouldHideNavigation from "@/hooks/useShouldHideNavigation";
import useAuthStore from "@/store";

const HeaderClient = ({ authToken }) => {
  const { me } = useAuthStore();
  const shouldHideNavigation = useShouldHideNavigation();

  return (
    <>
      <section
        className={`${styles.navLinks} ${
          shouldHideNavigation ? styles.viewHidden : ""
        }`}
      >
        <Link href="/" className={`${styles.navLink} link-text`}>
          Home
        </Link>
        <Link href="/styles" className={`${styles.navLink} link-text`}>
          Styles
        </Link>
        <Link href="/pricing" className={`${styles.navLink} link-text`}>
          Pricing
        </Link>
        <Link
          href={!!me.email ? "/pricing#faq" : "/#faq"}
          className={`${styles.navLink} link-text`}
        >
          FAQ
        </Link>
      </section>

      <section
        className={`${styles.headerSide} ${styles.headerButtons} ${
          shouldHideNavigation ? styles.viewHidden : ""
        }`}
      >
        {authToken ? (
          <Logout>
            <button className="secondary">Logout</button>
          </Logout>
        ) : (
          <Link href="/login">
            <button className="secondary">
              Login
              <RightArrow />
            </button>
          </Link>
        )}

        <GetYourHeadshotsButton />
      </section>

      <div
        className={`${styles.hamburgerMenuContainer} ${
          shouldHideNavigation ? styles.viewHidden : ""
        }`}
      >
        <HamburgerMenu authToken={authToken} />
      </div>
    </>
  );
};

export default HeaderClient;
