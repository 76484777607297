"use client";

import Link from "next/link";
import styles from "./Footer.module.css";
import FacebookIcon from "../../(svgIcons)/facebook.svg";
import TwitterIcon from "../../(svgIcons)/twitter.svg";
import InstagramIcon from "../../(svgIcons)/instagram.svg";
import Image from "next/image";
import useShouldHideNavigation from "@/hooks/useShouldHideNavigation";

const Footer = () => {
  const shouldHideNavigation = useShouldHideNavigation();

  if (shouldHideNavigation) return null;

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <section className={styles.footerCol} style={{ gap: "15px" }}>
            <Link href="/" className={styles.logoContainer}>
              <Image
                src="/staticImages/logoBodyTextColor.svg"
                fill
                priority
                alt="HeadshotClub"
              />
            </Link>

            <span className="body-text">
              Make amazing AI headshots from your selfies, effortlessly.
            </span>

            {/* <div
              className="flex-align-center"
              style={{ gap: "15px", marginTop: "3px" }}
            >
              <Link href="https://facebook.com">
                <FacebookIcon />
              </Link>
              <Link href="https://twitter.com">
                <TwitterIcon />
              </Link>
              <Link href="https://instagram.com">
                <InstagramIcon />
              </Link>
            </div> */}
          </section>

          <section className={styles.footerCol}>
            <h4>Quick links</h4>

            <div className={styles.footerLinksCol}>
              <Link className="body-text" href="/">
                Home
              </Link>
              <Link className="body-text" href="/pricing">
                Pricing
              </Link>
              <Link className="body-text" href="/company/refundpolicy.html">
                Refund Policy
              </Link>
              {/* <Link className="body-text" href="/affiliates">
                Affiliates
              </Link> */}
            </div>
          </section>

          <section className={styles.footerCol}>
            <h4>Privacy</h4>

            <div className={styles.footerLinksCol}>
              <Link className="body-text" href="/company/privacypolicy.html">
                Privacy Policy
              </Link>
              <Link className="body-text" href="/company/tos.html">
                Terms & Conditions
              </Link>
            </div>
          </section>

          <section className={styles.footerCol}>
            <h4>Contact</h4>

            <div className={styles.footerLinksCol}>
              <Link className="body-text" href="/customer-support">
                Customer Support
              </Link>
              {/* <Link className="body-text" href="/about-us">
                About Us
              </Link> */}
            </div>
          </section>
        </div>

        <span className={styles.yearText}>© 2024 Headshot.club</span>
      </div>
    </footer>
  );
};

export default Footer;
