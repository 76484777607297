"use client";

import useLoadMe from "@/hooks/useLoadMe";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const SECONDS = 60;
const MILISECONDS = 1000;

const staleTime = SECONDS * MILISECONDS;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime,
    },
  },
});

const RootProvider = ({ children }) => {
  useLoadMe({});

  return children;
};

export default function Providers({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <RootProvider>{children}</RootProvider>
    </QueryClientProvider>
  );
}
