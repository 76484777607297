import Link from "next/link";
import RightArrow from "../../(svgIcons)/rightArrow.svg";

const GetYourHeadshotsButton = () => {
  return (
    <Link href="/pricing">
      <button>
        Get Your Headshots
        <RightArrow />
      </button>
    </Link>
  );
};

export default GetYourHeadshotsButton;
