import { useState, useEffect } from "react";
import { usePathname } from "next/navigation";
import { pathsToHideNavigation } from "@/utils/pathsToHideNavigation";

const useShouldHideNavigation = () => {
  const pathname = usePathname();
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    const isModelRoute = /^\/models(\/new|\/[^/]+(\/upload)?)$/.test(pathname);

    const response = pathsToHideNavigation.some((path) =>
      path === "/models/:modelId" ? isModelRoute : pathname.includes(path)
    );
    setShouldHide(response);
  }, [pathname]);

  return shouldHide;
};

export default useShouldHideNavigation;
