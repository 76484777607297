"use client";

import { API_URL } from "../../app/config";
import { deleteCookie } from "cookies-next";

export const logout = () => {
  deleteCookie("auth");

  fetch(`${API_URL}/log_out`, { method: "DELETE" });

  window.location.replace("/login");
};

const Logout = ({ children }) => {
  return <div onClick={logout}>{children}</div>;
};

export default Logout;
