"use client";

import { useState } from "react";
import styles from "./HamburgerMenu.module.css";
import Link from "next/link";
import RightArrow from "../../../(svgIcons)/rightArrow.svg";
import Logout from "@/components/Logout/Logout";

const HamburgerMenu = ({ authToken }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen((prevValue) => !prevValue);

  return (
    <div className={styles.hamburgerContainer}>
      <div
        onClick={toggleIsOpen}
        className={isOpen ? styles.hamburgerOpen : styles.hamburger}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className={isOpen ? styles.mobileNavCardOpen : styles.mobileNavCard}>
        <section className={styles.navLinks}>
          <Link
            href="/"
            className={`${styles.navLink} link-text`}
            onClick={toggleIsOpen}
          >
            Home
          </Link>
          <Link
            href="/styles"
            onClick={toggleIsOpen}
            className={`${styles.navLink} link-text`}
          >
            Styles
          </Link>
          <Link
            href="/pricing"
            onClick={toggleIsOpen}
            className={`${styles.navLink} link-text`}
          >
            Pricing
          </Link>
          <Link
            href="/#faq"
            onClick={toggleIsOpen}
            className={`${styles.navLink} link-text`}
          >
            FAQ
          </Link>

          {authToken ? (
            <Logout>
              <button className="secondary">Logout</button>
            </Logout>
          ) : (
            <Link href="/login" onClick={toggleIsOpen}>
              <button className="secondary">
                Login
                <RightArrow />
              </button>
            </Link>
          )}

          <button style={{ alignSelf: "flex-start" }}>
            Get Your Headshots
            <RightArrow />
          </button>
        </section>
      </div>
    </div>
  );
};

export default HamburgerMenu;
